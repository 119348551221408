
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import api from '@/services/api';
import { notification } from 'ant-design-vue';
import utils from '@/services/utils';

export default {
  name: 'SharePreset',
  setup() {
    const router = useRouter();
    const store = useStore();
    const paths = window.location.pathname.split('/');
    const presetId = computed(() => paths[2]);

    const sharingPreset = computed(() => {
      return presetId.value;
    });

    const preset = ref({});

    const categoryTreeData = computed(
      () => store.getters['config/categoryTreeData'],
    );
    const sourceTreeData = computed(
      () => store.getters['account/sourceTreeData'],
    );

    onMounted(async () => {
      console.log('mounted', router);
      const presetIdValues = presetId.value;
      const currentPreset = await api
        .getUserFilterPresetById({
          presetId: presetIdValues,
        })
        .catch((error) => {
          console.log('error', error);
          const statusCode = error.response.status;
          if (statusCode === 409) {
            notification.error({
              message: 'Invalid link. Please check and try again.',
            });
            router.push('/');
          } else {
            router.push(`/error/${statusCode}`);
          }
        });
      if (currentPreset) {
        await store.dispatch('config/getAccountCategory');
        preset.value = currentPreset.message!;
        const beforeUpdate = store.state.filter;
        if (preset.value) {
          const presetCriteria = JSON.parse(preset.value.criteria);
          store.dispatch(
            'filter/updateTempCriteria',
            utils.prepareCriteria(
              presetCriteria,
              sourceTreeData.value,
              categoryTreeData.value,
            ),
          );
          store.dispatch('filter/applyFilter', { presetDirty: true });
        }

        const afterUpdate = store.state.filter;
        console.log('isChange? = ', beforeUpdate === afterUpdate);
        router.push('/');
      }
    });

    return {
      presetId,
      sharingPreset,
      preset,
    };
  },
};
