import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3db35b0b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex h-100 justify-content-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PresetHeader = _resolveComponent("PresetHeader")!
  const _component_PresetSearch = _resolveComponent("PresetSearch")!
  const _component_PresetItem = _resolveComponent("PresetItem")!
  const _component_PresetList = _resolveComponent("PresetList")!
  const _component_NoDataFound = _resolveComponent("NoDataFound")!
  const _component_NoDataAspect = _resolveComponent("NoDataAspect")!
  const _component_AddPreset = _resolveComponent("AddPreset")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, {
    style: {"height":"100%"},
    bodyStyle: { height: '100%' }
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_PresetHeader, { onAdd: $setup.onAdd }, null, 8, ["onAdd"]),
        _createVNode(_component_PresetSearch, {
          "preset-count": $setup.convertedPresets.length,
          class: "preset-search",
          onDataChange: $setup.onSearch
        }, null, 8, ["preset-count", "onDataChange"]),
        _createVNode(_component_PresetList, {
          ref: "listRef",
          class: "scroll-item",
          onScroll: $setup.handleScroll
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.convertedPresets, (item) => {
              return (_openBlock(), _createBlock(_component_PresetItem, {
                key: item.id,
                class: _normalizeClass(["preset-item cursor-pointer", $setup.selectedItemFromStore?.id === item.id ? 'selected' : '']),
                item: item,
                onClick: ($event: any) => ($setup.onSelect(item)),
                onCopyLink: $setup.onCopyLink,
                onDelete: $setup.onDelete,
                onFavorite: $setup.onFavorite
              }, null, 8, ["item", "class", "onClick", "onCopyLink", "onDelete", "onFavorite"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["onScroll"]),
        ($setup.convertedPresets.length === 0 && !$setup.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              ($setup.isSearch && $setup.convertedPresets.length === 0)
                ? (_openBlock(), _createBlock(_component_NoDataFound, { key: 0 }))
                : (_openBlock(), _createBlock(_component_NoDataAspect, {
                    key: 1,
                    title: "No Saved Presets"
                  }))
            ]))
          : _createCommentVNode("", true)
      ]),
      (_openBlock(), _createBlock(_component_AddPreset, {
        key: $setup.addPresetKey,
        visible: $setup.showAddPreset,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (($setup.showAddPreset) = $event)),
        onAdd: $setup.onAdded,
        "first-validate": true
      }, null, 8, ["visible", "onAdd"])),
      ($setup.selectedItem)
        ? (_openBlock(), _createBlock(_component_ConfirmModal, {
            key: 0,
            visible: $setup.showConfirmDelete,
            "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (($setup.showConfirmDelete) = $event)),
            "ok-title": "Delete",
            type: "error",
            title: "Confirm Delete",
            onClose: $setup.onConfirmClose,
            onConfirm: $setup.onConfirmDelete
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Are you sure to delete " + _toDisplayString($setup.selectedItem.preset.presetName) + " preset? ", 1)
            ]),
            _: 1
          }, 8, ["visible", "onClose", "onConfirm"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}